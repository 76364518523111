import React, { Suspense, lazy } from "react";
import ReactDOM from 'react-dom';
import "./assets/scss/style.scss";
import Spinner from "./views/spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { ActionCableProvider } from 'react-actioncable-provider';
import { WS_URL } from './env';

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./App")), 0);
    })
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <ActionCableProvider url={WS_URL}>
      <App />
    </ActionCableProvider>
  </Suspense>,
  document.getElementById("root")
);

